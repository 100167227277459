const crypto = require('crypto-js');
import handleExpiredTokens from '../utils/jwtErrorHandler';
import posthog from 'posthog-js';
class VisitorProfile {
  constructor() {
    var posthogKey = window.location.host.endsWith('tequitable.com') ? 'UXiD1rgebO7QKPXmy-NdLKZPRxSz0DiABgGTWmBSFyk' : 'phc_plKT55HsL1eY7nwc6rrwIAzedrYAbS40kEjzUJAOB8v';
    var posthogHost = window.location.host.endsWith('tequitable.com') ? 'https://teq-posthog-prod.herokuapp.com' : 'https://teq-posthog-staging.herokuapp.com';
    if (!window.location.host.includes('tequitable')) {
      posthogKey = 'phc_YcYsXpJvpjMy1KVFCzFPc5rlFgp1xYOEJ538CgHxaPs';
      posthogHost = 'https://teq-posthog-dev.herokuapp.com';
    }
    posthog.init(posthogKey, {api_host: posthogHost});

    this.departments = [];
    this.email_domains = [];
    this.offices = [];
    this.levels = [];
    this.relationships = [
      {label: 'my manager', mapping: 'manager_direct'},
      {label: 'a manager not in my chain', mapping: 'manager_other'},
      {label: 'a peer on my team', mapping: 'teammate'},
      {label: 'a co-worker not on my team', mapping: 'coworker'},
      {label: 'an external person (vendor, customer, ...)', mapping: 'external'}];
    this.softer_language = false; 
    this.is_authed = "";
    this.input_story_text = "";
    this.input_story_data = "";
    this.jwt_token = "";
    this.company_name = "";
    this.active = true; 
    this.orgType = "company"; 
    this.id = undefined;
    this.current_path_id = undefined;
    this.last_stories_route = "/solutions/storiesSearch?topics=&subtopics=";
    this.last_learning_modules_route = "/solutions/learningModulesSearch?category=";
    this.preauthenticated = false;
    this.departmentLabels = { question: "On what team?", prefix: "on the", suffix: "team"};
    this.officeLabels = { question: "In what location?", prefix: "in the", suffix: "office."};
    this.challengeAuth = false;
    this.mobileAuth = false;
    this.maintenanceMode = false;
    this.customResults = false;


    // If we've stored the visitor id in sessionStorage, get it now
    // It should usually be accessToken_visitorid
    var v_id = sessionStorage.getItem('v');
    if (v_id) {
      var delimiter = v_id.lastIndexOf('_');
      this.id = v_id.slice(delimiter+1);
      this.is_authed = v_id.slice(0, delimiter); 
      this.jwt_token = this.is_authed;
      if (sessionStorage.getItem('preauthed')) {
        this.preauthenticated = true;
      }
    }
    // If we've stored the visitor id in sessionStorage, get it now
    // It should usually be accessToken_visitorid
    var orgType = sessionStorage.getItem('orgType');
    if (orgType) {
      this.orgType = orgType;
    }
  }

  fetchCompanyInfo(fields = "") {
    return new Promise((resolve, reject)=> {
      // Get the company info given the subdomain of our url  
      fetch('/company/details/' + fields, {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type' : 'application/json',
            'Authorization': 'Bearer ' + this.getJwt(),
            'tEQ-preauthorized': visitor_profile.getPreauthorized()
            }
          })
        .then(res => {
          handleExpiredTokens(res)
          return res.json()
        })
        .then((data) => {
          this.setCompanyName(data.name); 
          if (data.departments)
            this.setDepartments(data.departments);
          if (data.email_domains)
            this.setEmailDomains(data.email_domains);
          if (data.offices)
            this.setOffices(data.offices);
          if (data.levels)
            this.setLevels(data.levels);
          if (data.relationships) 
            this.setRelationships(data.relationships);
          if (data.active !== undefined) {
            this.setActive(data.active);
          }
          if (data.is_organization !== undefined) {
            this.setOrgType(data.is_organization)
          }
          if (data.softer_language !== undefined) {
            this.setSofterLanguage(data.softer_language);
          }
          if (data.department_labels) {
            this.setDepartmentLabels(data.department_labels);
          }
          if (data.office_labels) {
            this.setOfficeLabels(data.office_labels);
          }

          resolve(data);
        })
        .catch(err => {console.log(err); reject(err)} );
    });
  }

  fetchCompanyInfoPublic() {
    return new Promise((resolve, reject)=> {
      // Get the company info given the subdomain of our url  
      fetch('/company', {
          headers: {
            'Cache-Control': 'no-cache',
            'Content-Type' : 'application/json'
            }
          })
        .then(res => res.json())
        .then((data) => { 
          if (data.error) {
            reject(data.error);
          }
          this.setCompanyName(data.name); 
          if (data.email_domains)
            this.setEmailDomains(data.email_domains);
          if (data.active !== undefined) {
            this.setActive(data.active);
          }

          if (data.is_organization !== undefined) {
            this.setOrgType(data.is_organization)
          }

          if (data.mobile_auth !== undefined) {
            this.setMobileAuth(data.mobile_auth);
          }

          if (data.challenge_auth !== undefined) {
            this.setChallengeAuth(data.challenge_auth);
          }

          if (data.maintenance_mode !== undefined) {
            this.setMaintenanceMode(data.maintenance_mode);
          }

          if(!sessionStorage.getItem('story')){
            sessionStorage.setItem('story', "");

          }

          if(!sessionStorage.getItem('dropdowns')){
            sessionStorage.setItem('dropdowns', "");

          }
          

          resolve(data);
        })
        .catch(err => {console.log("FAIL", err); reject(err)} );
    });
  }

  preauthenticatedVisitor(key, dest) {
    return new Promise((resolve, reject) => {
      fetch('/visitor/preauth', {
        method: 'POST',
        body: JSON.stringify({ key, dest: decodeURIComponent(dest) }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json' 
        }
      }).then(res => res.json())
      .then(visitor_data => { 
        if (visitor_data.error) {
          reject(visitor_data.error);
        } else {
          visitor_profile.setIsAuthenticated(visitor_data.token);
          this.setId(visitor_data.id);
          this.setJwt(visitor_data.token);
          this.preauthenticated = true;
          sessionStorage.setItem('preauthed', true);
          sessionStorage.setItem('story', "");
          sessionStorage.setItem('dropdowns', "");

          this.getFeatureFlags().then((featureFlags) => {
            posthog.identify(
              '' + visitor_data.id, // distinct_id, required
              { 
                preauthed: true,
                companyName: this.getCompanyName(),
                '$active_feature_flags': featureFlags
              }
            );
            resolve();
          });
        }
      })
      .catch(err => {console.log(err); reject(err);}); 
    });
  }

  //Because adding challenge/captcha is leveraging our preauthentication logic we did not want to modify the original functions since it would increase possibility of breaking current functionality and possibly slow down development. We created a new function instead which made it easier to encapsulate changes necessary for the test.

  preauthedChallengeVisitor(challenge_id, user_response) {
    return new Promise((resolve, reject) => {
      const visitor_id = this.getId();
      
      fetch('/visitor/validate', {
        method: 'POST',
        body: JSON.stringify({
          challengeId: challenge_id,
          visitorId: visitor_id,
          visitorResponse: user_response
        }),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json' 
        }
      }).then(res => res.json())
      .then(visitor_challenge_data => { 
        if (visitor_challenge_data.error) {
          reject(visitor_challenge_data.error);
        } else if(!visitor_challenge_data?.token){
          return resolve(visitor_challenge_data);

          
        }else {
          visitor_profile.setIsAuthenticated(visitor_challenge_data.token);
          this.setId(visitor_id); 
          this.setJwt(visitor_challenge_data.token);
           this.preauthenticated = true;
          sessionStorage.setItem('preauthed', true);
          this.getFeatureFlags().then((featureFlags) => {
            
            posthog.identify(
              '' + visitor_id, // distinct_id, required
              { 
                challenge_preauthed: true,
                companyName: this.getCompanyName(),
                '$active_feature_flags': featureFlags,
                '$set': {attemptCount:visitor_challenge_data.attemptCount }
              }
            );
            resolve(visitor_challenge_data);
          });
        }
      })
      .catch(err => {console.log(err); reject(err);}); 
      
    })
  };


  createVisitor() {
    return new Promise((resolve, reject)=> {
      // If we already have created a visitor, don't create a new one
      // In particular, refreshing on a page should not create a new visitor
      // And going to the home page and then hitting the back button should 
      // not create a new visitor 
      if (!sessionStorage.getItem('v')) 
      {
        // Then create a visitor for the given company
        fetch('/visitor', {
          method: 'POST',
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json' }
          })
          .then(res => res.json())
          .then(visitor_data => { 
            this.setId(visitor_data.id);
            this.getFeatureFlags().then((featureFlags) => {
              posthog.identify(
                '' + visitor_data.id, // distinct_id, required
                { 
                  companyName: this.getCompanyName(),
                  preauthed: false,
                  '$active_feature_flags': featureFlags
                }
              );
              resolve(); 
            });
          })
          .catch(err => {console.log(err); reject(err);}); 
      }
      else
        resolve();
    });
  }

  recordVisitorConsent(value) {
    return new Promise((resolve, reject)=> {

      // save the user hash_value to the database 
      fetch('/visitor/consent', {
          method: 'POST',
          body: JSON.stringify({visitor_id: this.id, 
                                consented: value }),
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(resolve())
        .catch(err => reject(err));
    });
  }

  rememberVisitor(value, redirect_to) {
    return new Promise((resolve, reject)=> {
      const hash = crypto.SHA256(value);
      
      // save the user hash_value to the database 
      fetch('/visitor/remember', {
          method: 'POST',
          body: JSON.stringify({visitor_id: this.id, 
                                hash_value: hash.toString(),
                                redirect_to
                              }),
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json' }
        })
        .then(res => res.json())
        .then(resolve())
        .catch(err => reject(err));
    });
  }

  linkVisitor(value) {
    return new Promise((resolve, reject)=> {

      const hash = crypto.SHA256(value);

      // find and match the user hash_value from the database 
      fetch('/visitor/link', {
          method: 'POST',
          body: JSON.stringify({hash_value: hash.toString() }),
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + this.getJwt(),
           'tEQ-preauthorized': visitor_profile.getPreauthorized() }
        })
        .then(res => {
          if (!res.ok){
            handleExpiredTokens(res)
            throw Error(res.statusText)
             }
          else
            return res.json(); 
        })
        .then(json => {
          // on success we will set the user_id so that they may contine with the same 
          // id as the one created on the homepage.
          this.setId(json.id);
          resolve(json.redirect_to); 
        })
        .catch(err => {
          this.fetchCompanyInfoPublic()
            .then(() => {
              // If we're in a non-live instance and we found an id in sessionStorage
              // then re-set the id and proceed as usual. This is most likely because 
              // of the password flow. 
              if (this.getActive() === false && this.id !== undefined)
              {
                this.setId(this.id);
                resolve();
              }
              else {
                throw Error("Link failed"); 
              }
            })
            .catch(err => {
              // clear what we have in storage, it's not a valid visitor id
              this.setIsAuthenticated("");
              this.setJwt("");
              this.id = undefined;
              this.preauthenticated = false;
              sessionStorage.removeItem('v');
              console.log(err); 
              reject(err);
            });
        });
    });
  }

  recordStory(story_text, office="", department_mapping="", department_label="", level="", level_label="", relationship="", behavior="", perspective="", identities=[]) {
    return new Promise((resolve, reject)=> {
      // save the story to the database 
      fetch('/visitor/story', {
          method: 'POST',
          body: JSON.stringify({visitor_id: this.id, 
                                story: story_text, 
                                office,
                                department : department_mapping,
                                department_label : department_label,
                                level,
                                level_label,
                                relationship,
                                behavior,
                                perspective,
                                identities }),
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + this.getJwt(),
           'tEQ-preauthorized': visitor_profile.getPreauthorized() }
        })
        .then(res =>{
          handleExpiredTokens(res)
           res.json()})
        .then(resolve())
        // TURN OFF RECOMMENDATIONS. See TE-1472 and TE-1532
        //.then (this.getRecommendations)
        .catch(err => reject(err));
    });
  }

  recordInteractiveData(title, data="") {
    return new Promise((resolve, reject)=> {
      // save the interactive module to the database 
      fetch('/visitor/interactive', {
          method: 'POST',
          body: JSON.stringify({visitor_id: this.id, 
                                title: title, 
                                data : data }),
          headers: {
           'Accept': 'application/json',
           'Content-Type': 'application/json',
           'Authorization': 'Bearer ' + this.getJwt(),
           'tEQ-preauthorized': visitor_profile.getPreauthorized() }
        })
        .then(res =>{
          handleExpiredTokens(res)
          return res.json()
        })
        .then(resolve())
        .catch(err => reject(err));
    });
  }

  getRecommendations() {
    return new Promise((resolve, reject) => {
      fetch("/ai/recommendV2/" + visitor_profile.getId(), {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: "Bearer " + visitor_profile.getJwt(),
          "tEQ-preauthorized": visitor_profile.getPreauthorized(),
        },
      })
      .then((res) => {
        if (!res.ok) {
          reject();
        } else {
          return res;
        }
      })
        .then((res) => res.json())
        .then((data) => {
          resolve(
            data.content.map((s) => {
              return {
                ...s,
              };
            })
          );
          analyticsTracker.trackEvent({
            category: "User",
            action: "Saw custom results",
            data,
          });
        })
        .catch((err) => reject(err));
    });
  
}

  recordPath(path, queryString = "") {
    // save the path, source, (and optional query string) to the database
    if (path) {
        // if the user is coming from the homepage the source should be "start".
        let source = (sessionStorage.getItem('prevPath') === null && path === "homepage") ? "start" : sessionStorage.getItem('prevPath');
        return fetch('/visitor/path', {
                    method: 'POST',
                    body: JSON.stringify({visitor_id: this.id, path: path, source: source, query_string: queryString}),
                    headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json' }
                    })
                    .then(res => res.json())
                    .then(data => { this.setCurrentPathId(data.id); })
                    .catch(err => console.log("Failed to record visitor path"));
    } else {
      console.log("No path id specified");
    }
  }

  recordPathRelevance(relevance, location) {
    return new Promise((resolve, reject)=> {

      let path_id = this.getCurrentPathId();

      // save the relevance of the path to the database
      fetch('/visitor/path/relevance', {
                  method: 'POST',
                  body: JSON.stringify({visitor_id: this.id, hash: location.hash.replace("#", ''), path_id: path_id, relevance: relevance}),
                  headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getJwt(),
                   'tEQ-preauthorized': visitor_profile.getPreauthorized() }
                  })
                  .then(res =>{
                    handleExpiredTokens(res)
                    return resolve()
                  })
                  .catch(err => reject(err));
    });
  }

  recordPathConfidence(confidence, location) {
    return new Promise((resolve, reject)=> {

      let path_id = this.getCurrentPathId();

      // save the relevance of the path to the database
      fetch('/visitor/path/confidence', {
                  method: 'POST',
                  body: JSON.stringify({visitor_id: this.id, hash: location.hash.replace("#", ''), path_id: path_id, confidence: confidence}),
                  headers: {
                   'Accept': 'application/json',
                   'Content-Type': 'application/json',
                   'Authorization': 'Bearer ' + this.getJwt(),
                   'tEQ-preauthorized': visitor_profile.getPreauthorized() }
                  })
                  .then(res => {
                    handleExpiredTokens(res)
                    return res.json()
                  })
                  .then(resolve())
                  .catch(err => reject(err));
    });
  }

  recordAppointmentScheduled() {
    posthog.identify('' + this.getId(), { appointmentScheduled: true });
  }

  recordAppintmentEmailSent() {
    posthog.identify('' + this.getId(), { appointmentEmailSent: true });
  }

  waitForFeatureFlags(fn) {
    // TODO: handle infinite loop possibility
    this.featureFlagRetreivalAttempts = 0;
    if (posthog.feature_flags.getFlags().length > 0) {
      fn(posthog.feature_flags.getFlags());
    } else {
      this.featureFlagRetreivalAttempts++;
      setTimeout(() => {
        this.waitForFeatureFlags(fn) 
      }, 200);
      
    }
  }

  getFeatureFlags() {
    return new Promise((resolve, reject) => {
      this.waitForFeatureFlags(resolve);
    });
  }

  ifFeatureEnabled(feature) {
    return new Promise((resolve, _reject) => {
      this.waitForFeatureFlags(() => {
        if (posthog.isFeatureEnabled(feature)) {
          posthog.capture('Feature Enabled', {
              feature
          });
        } else {
          posthog.capture('Feature NOT Enabled', {
              feature
          });
        }
        resolve(posthog.isFeatureEnabled(feature));
      });
    });
  }

  waitForId(fn) {
    if (this.getId()) {
      fn(this.getId());
    } else {
      setTimeout(() => { this.waitForId(fn) }, 100);
    }
  }

  getId() {
    return this.id;
  }

  setId(visitor_id) {
    this.id = visitor_id;
    sessionStorage.setItem('v', this.is_authed + '_' + visitor_id);
  }

  setStoryText(story_text){
    sessionStorage.setItem('story', story_text);
  }

  getStoryText(){
    this.input_story_text = sessionStorage.getItem('story');
    return this.input_story_text;

  }

  setStoryDropDowns(story_data){
    const dataToSet = story_data !== "" ? JSON.stringify(story_data): ""
    sessionStorage.setItem('dropdowns', dataToSet );
  }

  getStoryDropDowns(){
    this.input_story_data = sessionStorage.getItem('dropdowns')?JSON.parse(sessionStorage.getItem('dropdowns')):"";
    
    return this.input_story_data;
  }

  getIsAuthenticated() {
    return this.is_authed !== "" ? true : false;
  }

  setIsAuthenticated(accessToken) {
    this.is_authed = accessToken;
  }

  setJwt(token) {
    this.jwt_token = token;
  }

  getJwt() {
    return this.jwt_token;
  }

  setCompanyName(name) {
    this.company_name = name;
  }

  getCompanyName() {
    return this.company_name;
  }

  setActive(active) {
    this.active = active;
  }

  getActive() {
    return this.active;
  }

  setOrgType(is_org) {
    if (is_org)
      this.orgType = "organization";

    sessionStorage.setItem('orgType', this.orgType);
  }

  getOrgType() {
    return this.orgType;
  }

  setDepartments(departments) {
    this.departments = departments;
  }

  getDepartments() {
    return this.departments;
  }

  setEmailDomains(domains) {
    this.email_domains = domains;
  }

  getEmailDomains() {
    return this.email_domains;
  }

  setOffices(offices) {
    this.offices = offices;
  }

  getOffices() {
    return this.offices;
  }

  setLevels(levels) {
    this.levels = levels;
  }

  getLevels() {
    return this.levels;
  }

  setRelationships(newRelationships) {
    this.relationships = newRelationships;
  }

  getRelationships() {
    return this.relationships;
  }

  setSofterLanguage(softer_language) {
    this.softer_language = softer_language;
  }

  getSofterLanguage() {
    return this.softer_language;
  }

  setCurrentPathId(path_id) {
    this.current_path_id = path_id;
  }

  getCurrentPathId() {
    return this.current_path_id;
  }

  setLastStoryFilterRoute(location) {
    this.last_stories_route = location.pathname + location.search;
  }

  setLastLearningModuleFilterRoute(location){
     this.last_learning_modules_route = location.pathname + location.search;
  }

  getLastStoryFilterRoute() {
    return this.last_stories_route;
  }

  getPreauthorized() {
    return this.preauthenticated;
  }

  setDepartmentLabels(labels) {
    Object.keys(labels).forEach(k => {
      this.departmentLabels[k] = labels[k] || this.departmentLabels[k];
    });
  }

  getDepartmentLabels() {
    return this.departmentLabels;
  }

  setOfficeLabels(labels) {
    Object.keys(labels).forEach(k => {
      this.officeLabels[k] = labels[k] || this.officeLabels[k];
    });
  }

  getOfficeLabels() {
    return this.officeLabels;
  }

  setMobileAuth(ma) {
    this.mobileAuth = ma;
    sessionStorage.setItem('mobileAuth', ma);
  }

  getMobileAuth() {
    return this.mobileAuth;
  }

  setChallengeAuth(ca) {
    this.challengeAuth = ca;
    sessionStorage.setItem('challengeAuth', ca);
  }

  getChallengeAuth() {
    return this.challengeAuth;
  }


  setMaintenanceMode(mm) {
    this.maintenanceMode = mm;
  }

  getMaintenanceMode() {
    return this.maintenanceMode;
  }

  setCustomResults(data) {
    this.customResults = data;
  }

  getCustomResults() {
    return this.customResults;
  }
}

const visitor_profile = new VisitorProfile();


export default visitor_profile;